import { useEffect, useState, useRef } from "react";
import { Wheel } from "../../app/utils/react-custom-roulette/components/Wheel";
import "./SpinWheel.css";
import SpinEffect from "./SpinEffect";
import { WheelProps } from "../../app/models/wheelProps";
import { getWinningSlice } from "../../app/utils/getWinningSlice";
import {
  Modal,
  Box,
  Typography,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Swal from "sweetalert2";
import {v4 as uuid} from "uuid";
import agent from "../../app/api/agent";
import { Player } from "../../app/models/player";
import { env } from "../../app/utils/env";
import { PointerPin, WheelContainer } from "./wheelStyles";
import { validateImageUrl } from "../../app/utils/validateImages";
import { Slice } from "../../app/models/slice";
import { FormStateProps } from "../../app/models/formStateProps";
import parse from 'html-react-parser';

interface SpinWheelProps {
  collectUserData: number;
  wheel: WheelProps['wheel'];
  slices: WheelProps['slices'];
  formState: FormStateProps['formState'];
  campaignId: string;
  onStockDepletion: () => void;
  modalText?: string;
  modalBg?: string;
}

const SpinWheel: React.FC<SpinWheelProps> = ({ collectUserData, wheel, slices, formState, campaignId, onStockDepletion, modalText, modalBg }) => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [effect, setEffect] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [resetWheel, setResetWheel] = useState(false);
  const [player, setPlayer] = useState<Player | null>(null);
  const [slicesState, setSlicesState] = useState(slices);

  const initialFormValues: any = {
    firstName: "",
    lastName: "",
    email: formState.formEmail ? "" : undefined,
    phone: formState.formPhone ? "" : undefined,
    company: formState.formCompany ? "" : undefined,
    gender: formState.formGender ? 1 : undefined,
    ...(formState.bonusField && { [formState.bonusField]: "" }),
  };
  const [formValues, setFormValues] = useState(initialFormValues);

  const [dataCollected, setDataCollected] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(true);

  let playerId = null;

  const nonNullSlices = slicesState.filter(slice => slice.probability !== null);
  const areAllProbabilitiesZero = nonNullSlices.length > 0 && nonNullSlices.every(slice => slice.probability === 0);

  const audioRef = useRef(new Audio(`${process.env.PUBLIC_URL}/assets/wheel-turn-effect.mp3`));  

  const handleSpinClick = () => {
    setEffect(false);
    if (!mustSpin) {
      const validSlices = slicesState.filter(slice => slice.probability! > 0);
      let winnerId: string;
      if (validSlices.length > 0) {
        winnerId = getWinningSlice(validSlices);
      } else {
        winnerId = getWinningSlice(slicesState);
      }
      
      const winnerSlice = slicesState.find(slice => slice.id == winnerId);
      const newPrizeNumber = slicesState.indexOf(winnerSlice!);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };

  useEffect(() => {
    if (mustSpin) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [mustSpin]);

  useEffect(() => {
    if (areAllProbabilitiesZero) {
      Swal.fire({
        title: "Oyun Bitti",
        text: "Oyun sona erdi. Katıldığınız için teşekkürler.",
        icon: "info",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCloseButton: false,
        showConfirmButton: false,
      });
  
      setMustSpin(false);
    }
  }, [slicesState]);

  useEffect(() => {
    const validateImages = async () => {
      const updatedSlices: Slice[] = await Promise.all(
        slicesState.map(async (slice): Promise<Slice> => {
          const isValidImage = slice.wedgeImage
            ? await validateImageUrl(env + slice.wedgeImage)
            : false;
  
          return {
            ...slice,
            wedgeImage: isValidImage ? slice.wedgeImage : "",
          };
        })
      );
  
      setSlicesState(updatedSlices);  // Update slices state with validated images
      setValidated(true);  // Mark validation as complete
      setLoading(false);    // Allow the Wheel to be rendered
    };
  
    if (!validated) {
      validateImages();
    }
  }, [slicesState, validated]);


  const handleClose = () => {
    if (collectUserData != 2 && !dataCollected) {
      setPlayer(null);
    }
    setOpenModal(false);
    setResetWheel(true);
    setEffect(true);
    setFormValues({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      gender: 1,
    });
  };

  useEffect(() => {
    if (resetWheel) {
      setMustSpin(false);
      setResetWheel(false);
    }
  }, [resetWheel]);

  const buildPlayerData = (formValues: any, formState: FormStateProps['formState'], campaignId: string, playerId: string) => {
    return {
      id: playerId,
      campaignId,
      firstName: formValues.firstName || "",
      lastName: formValues.lastName || "",
      gender: formState.formGender ? formValues.gender === 1 ? 1 : 2 : 0,
      email: formState.formEmail ? formValues.email || "" : "",
      phone: formState.formPhone ? formValues.phone || "" : "",
      company: formState.formCompany ? formValues.company || "" : "",
      bonusField: formState.bonusField ? formValues[formState.bonusField] || "" : "",
      bonusFieldLabel: collectUserData != 1 ? formState.bonusField || "" : "",
    };
  };

  const onWheelStop = async () => {
    setMustSpin(false);

    const selectedSlice = slices[prizeNumber];
    const oldStock = slicesState[prizeNumber].stock;

    Swal.fire({
      title: selectedSlice.prizeValue,
      text: modalText ? modalText : (selectedSlice.isPrize
        ? `Tebrikler! Hediye kazandınız! ${collectUserData === 3 ? "Hediyenizi almak için kayıt oluşturun." : ""}`
        : "Maalesef kazanamadınız."),
      icon: selectedSlice.isPrize ? "success" : "error",
      imageUrl: env + selectedSlice.prizeImage || undefined,
      imageWidth: 200,
      showCancelButton: collectUserData === 3 ? true : false,
      showConfirmButton: collectUserData === 3 ? true : false,
      confirmButtonText: "Kullanıcı Formu",
      cancelButtonText: "Başa Dön",
      customClass: {
        popup: modalBg ? 'swal-container swal-bg' : 'swal-container',
        icon: modalBg ? 'd-none' : 'swal-icon',
        image: selectedSlice.prizeImage ? 'swal-image' : 'd-none',
        title: modalBg && 'd-none',
        htmlContainer: modalBg && 'd-none'
      },
      background: modalBg ? `url(${env + modalBg})` : '#fff',
      didOpen: () => {
        if (collectUserData != 1) {
          document.addEventListener('click', () => {
            Swal.close();
          });
        }
      },
    }).then(async (result) => {
      playerId = uuid();
      const gameData = {
        id: uuid(),
        prize: selectedSlice.prizeValue,
        isPrize: selectedSlice.isPrize,
        datePlayed: new Date().toISOString(),
        campaignId: campaignId,
        playerId: playerId,
        player: buildPlayerData(formValues, formState, campaignId, playerId)
      };
      if (collectUserData === 2 && player != null) {
        gameData.player = player;
        gameData.playerId = player.id;
      }
      if (selectedSlice.isPrize && oldStock! > 0) {
        const sliceData = {
          ...selectedSlice,
          stock: oldStock! - 1,
          wheelId: wheel.id
        }
        const updatedSlices = slicesState.map(slice => 
          slice.id === sliceData.id 
            ? { ...slice, stock: sliceData.stock }
            : slice
        );
        setSlicesState(updatedSlices);

        await agent.Slices.update(sliceData);

        onStockDepletion();

        // Check and update probability if stock becomes 0
        if (sliceData.stock <= 0) {
          const updatedSlicesWithProbability = updatedSlices.map(slice => 
            slice.id === sliceData.id 
              ? { ...slice, probability: 0 }
              : slice
          );
          setSlicesState(updatedSlicesWithProbability);
          
        }
      }
      setPlayer(gameData.player);
      await agent.Games.create(gameData);
      if (collectUserData == 3 && result.isConfirmed) {
        setOpenModal(true);
      } else {
        setPlayer(null);
        setResetWheel(true);
        setEffect(true);
        setDataCollected(false);
      }
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: name === 'gender' ? parseInt(value) : value
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const playerId = player?.id || uuid();

    const playerData = buildPlayerData(formValues, formState, campaignId, playerId);
    if (collectUserData === 2) {
      setPlayer(playerData);
      setDataCollected(true);
    } else {
      await agent.Players.update(playerData);
    }

    handleClose();
  };

  return (
    <>
      <WheelContainer
        className={openModal ? 'wheel-container disable-clicks' : 'wheel-container'}
        decorated={wheel.spinnerDecoration}
        imageUrl={wheel.spinnerImage ? env + wheel.spinnerImage : `${process.env.PUBLIC_URL}/assets/wheel-decoration.png`}
        spinnerRotate={wheel.spinnerRotate}
        spinnerCoverOpacity={wheel.spinnerCoverOpacity}
        onClick={areAllProbabilitiesZero ? undefined : handleSpinClick}
      >
        <PointerPin
          className={"pointer-pin " + (mustSpin ? "spinning" : "")}
          spinDuration={wheel.spinDuration}
          speedMode={wheel.spinSpeed === 2 ? true : false}
          pinColor={wheel.wheelPinColor || `#eeeeee`}
          bgImage={wheel.wheelPinImage && env + wheel.wheelPinImage}
        />
        <img src={wheel.circleImage ? env + wheel.circleImage : `${process.env.PUBLIC_URL}/assets/eddra-emblem.png`} className="center-img" alt="" />
        {!loading && (
          <Wheel
            mustStartSpinning={mustSpin}
            onStopSpinning={() => onWheelStop()}
            prizeNumber={prizeNumber}
            spinDuration={wheel.spinDuration / 10}
            data={slicesState.map((slice) => ({
              option: wheel.showPrizeValues ? slice.prizeValue : "",
              ...(slice.wedgeImage && {
                image: {
                  uri: env + slice.wedgeImage,
                  sizeMultiplier: 1.7,
                  offsetY: 80,
                },
              }),
              probability: slice.probability ? parseFloat(slice.probability.toFixed(3)) : undefined,
            }))}
            startingOptionIndex={0}
            coverImage={wheel.spinnerCover}
            backgroundColors={
              wheel.spinnerCover ? ["transparent"] : [
              wheel.colorFirst || "#fff",
              wheel.colorSecond || "#E60F0F"
            ]}
            textColors={[
              wheel.wheelTextColor || "#3e3e3e",
              wheel.wheelTextColor2 || "#fff",
            ]}
            outerBorderColor={wheel.wheelBorderColor || "#E60F0F"}
            outerBorderWidth={ wheel.spinnerCover ? 0 : 10}
            innerRadius={20}
            radiusLineWidth={wheel.radiusLine ? 5 : 0}
            radiusLineColor={wheel.radiusLineColor || "e60f0f"}
            backwardsAnimation={!wheel.clockwise}
            noAnimationSteps={wheel.spinSpeed === 2 ? true : false}
            spinnerRotate={wheel.spinnerRotate}
          />
        )}
        {effect && wheel.spinnerEffect && (
          <SpinEffect mustSpin={mustSpin} dataLength={slices.length} />
        )}
      </WheelContainer>
      {/* <button
        className="start-button"
        onClick={areAllProbabilitiesZero ? undefined : handleSpinClick}
      >
        <img src={process.env.PUBLIC_URL + "/assets/redhat-button.png"} />
      </button> */}
      <Modal
        open={collectUserData === 2 && !dataCollected ? true : openModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="game-modal modal"
      >
        <Box
          sx={{
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
          }}
        >
          <p>Lütfen bilgilerinizi giriniz.</p>
          <form className="login-form" onSubmit={handleSubmit}>
            <Grid container sx={{ justifyContent: "space-between" }}>
              <Grid item xs={5}>
                <TextField
                  label="Adınız"
                  name="firstName"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formValues.firstName}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Soyadınız"
                  name="lastName"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formValues.lastName}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
            </Grid>

            {formState.formEmail && <TextField
              label="E-Posta"
              name="email"
              type="email"
              fullWidth
              variant="outlined"
              value={formValues.email}
              onChange={handleInputChange}
            />}

            {formState.formPhone &&<TextField
              label="Telefon"
              name="phone"
              type="text"
              fullWidth
              variant="outlined"
              value={formValues.phone}
              onChange={handleInputChange}
              required
            />}
            {formState.formCompany &&<TextField
              label="Firma Adı"
              name="company"
              type="text"
              fullWidth
              variant="outlined"
              value={formValues.company}
              onChange={handleInputChange}
              required
            />}

            {formState.formGender && <>
              <Typography variant="subtitle2" sx={{ textAlign: "left" }}>
                Cinsiyet
              </Typography>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="gender"
                value={formValues.gender}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Erkek"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Kadın"
                />
              </RadioGroup>
            </>}
            {formState.bonusField &&<TextField
              label={formState.bonusField}
              name={formState.bonusField}
              type="text"
              fullWidth
              variant="outlined"
              value={formValues[formState.bonusField]}
              onChange={handleInputChange}
              required
            />}

            <FormGroup className="form-control-contracts">
              {formState.formContract &&
              <FormControlLabel
                required
                control={<Checkbox />}
                label={
                  <small>{parse(formState.formContract)}</small>
                }
              />}
              {formState.formAddPermission &&
              <FormControlLabel
                required
                control={<Checkbox />}
                label={
                  <small>{formState.formAddPermission}</small>
                }
              />}
            </FormGroup>
            <Button
              variant="contained"
              type="submit"
              sx={{ my: 4, backgroundColor: "#FAB406" }}
            >
              Gönder
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default SpinWheel;
